// Entry point for the build script in your package.json
import './add_jquery'
import * as bootstrap from "bootstrap"
import EasyMDE from "easymde"
import { InitEasyMDE } from "./add_easy_mde"
import { InitTagify } from "./add_tagify"
import { Turbo } from "@hotwired/turbo-rails";
Turbo.setFormMode("on")

function initFunc() {
  // Article.new
  if($("#create-publish-article").length) {
    $("#create-publish-article").on("click", function() {
      $("#published").val("true");
    });
  }

  // Create / Update article
  if($("#text-mde").length) {
    if($('.EasyMDEContainer').length == 0) {
      InitEasyMDE("#text-mde");
    } else {
      return;
    }
  }

  // Create/update article
  if($("#tags").length) {
    InitTagify("#tags");
  }
}

$(document).on("turbo:load", initFunc);
$(document).on("turbo:render", initFunc);

