import Tagify from '@yaireo/tagify'
import { GenerateSlugUID, GetWhitelistTags } from './shared'

export function InitTagify(elid) {
  let uid = GenerateSlugUID();
  let wl = GetWhitelistTags();

  tf = new Tagify($(elid)[0], {
    id: uid,  // must be unique (per-tagify instance)
     
    //SET WHITELIST
    whitelist : wl,
    dropdown : {
        classname     : "color-blue",
        enabled       : 0,              // show the dropdown immediately on focus
        maxItems      : 5,
        position      : "text",         // place the dropdown near the typed text
        closeOnSelect : false,          // keep the dropdown open after selecting a suggestion
        highlightFirst: true
    }
  });

  // TO GET STRING FORMAT cat,dog
  // originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
};