import EasyMDE from "easymde";
import { GenerateSlugUID } from './shared'

export function InitEasyMDE(id) {
  let uid = GenerateSlugUID();

  const easyMDE = new EasyMDE({
    element: $(id)[0],
    toolbarTips: true,
    styleSelectedText: true,
    minHeight: "50vh",
    initialValue: $(id).html(),
    
    status: ["autosave", "lines", "words", "cursor", {
      className: "keystrokes",
      defaultValue: (el) => {
          el.setAttribute('data-keystrokes', 0);
      },
      onUpdate: (el) => {
          const keystrokes = Number(el.getAttribute('data-keystrokes')) + 1;
          el.innerHTML = `${keystrokes} Keystrokes`;
          el.setAttribute('data-keystrokes', keystrokes);
      },
    }], // Another optional usage, with a custom status bar item that counts keystrokes
    
    autosave: {
      enabled: true,
      uniqueId: uid,
      delay: 1000,
      submit_delay: 5000,
      timeFormat: {
          locale: 'en-GB',
          format: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
          },
      },
      text: "Autosaved: "
    },
    
    // lineWrapping: false,
    // spellChecker: false,
    // sideBySideFullscreen: false,
    // syncSideBySidePreviewScroll: false,
    // tabSize: 4,

    // showIcons: ["code", "table"],
    // insertTexts: {
    //   horizontalRule: ["", "\n\n-----\n\n"],
    //   image: ["![](http://", ")"],
    //   link: ["[", "](https://)"],
    //   table: ["", "\n\n| Column 1 | Column 2 | Column 3 |\n| -------- | -------- | -------- |\n| Text     | Text      | Text     |\n\n"],
    // },

    // autofocus: true,
   
    // blockStyles: {
    //     bold: "__",
    //     italic: "_",
    // },
    // unorderedListStyle: "-",
    // forceSync: true,
    // hideIcons: ["guide", "heading"],
    // indentWithTabs: false,
    // parsingConfig: {
    //     allowAtxHeaderWithoutSpace: true,
    //     strikethrough: false,
    //     underscoresBreakWords: true,
    // },

    // placeholder: "Type here...",
    // previewClass: "my-custom-styling",
    // previewClass: ["my-custom-styling", "more-custom-styling"],

    // previewRender: (plainText) => customMarkdownParser(plainText), // Returns HTML from a custom parser
    // previewRender: (plainText, preview) => { // Async method
    //     setTimeout(() => {
    //         preview.innerHTML = customMarkdownParser(plainText);
    //     }, 250);

    //     return "Loading...";
    // },
    // promptURLs: true,
    // promptTexts: {
    //     image: "Custom prompt for URL:",
    //     link: "Custom prompt for URL:",
    // },
    // renderingConfig: {
    //     singleLineBreaks: false,
    //     codeSyntaxHighlighting: true,
    //     sanitizerFunction: (renderedHTML) => {
    //         // Using DOMPurify and only allowing <b> tags
    //         return DOMPurify.sanitize(renderedHTML, {ALLOWED_TAGS: ['b']})
    //     },
    // },
    // shortcuts: {
    //     drawTable: "Cmd-Alt-T"
    // },
    // status: false,
    // status: ["autosave", "lines", "words", "cursor"], // Optional usage
    // toolbar: false,
  });

  const existingValue = $(id).html();
  if (existingValue) {
    easyMDE.value(existingValue)
  }
};
