export function GenerateSlugUID() {
  let uid = "";
  if ($("#slug").length) {
    uid = $("#slug").val();
  } else {
    uid = new Date().toISOString().slice(0,10);
  }
  return uid
}

export function GetWhitelistTags() {
  if ($("#tags-all").length) {
    return JSON.parse($("#tags-all").html());
  }
  return [];
}